import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import { defineComponent } from "vue";
import AnswerPc from "./components/AnswerPc.vue";
import AnswerMob from "./components/AnswerMob.vue";
export default defineComponent({
  components: {
    AnswerPc: AnswerPc,
    AnswerMob: AnswerMob
  },
  setup: function setup() {
    var browser = {
      versions: function () {
        var u = navigator.userAgent;
        return {
          //移动终端浏览器版本信息
          trident: u.indexOf("Trident") > -1,
          //IE内核
          presto: u.indexOf("Presto") > -1,
          //opera内核
          webKit: u.indexOf("AppleWebKit") > -1,
          //苹果、谷歌内核
          gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1,
          //火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/),
          //是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
          //ios终端
          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
          //android终端或uc浏览器
          iPhone: u.indexOf("iPhone") > -1,
          //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf("iPad") > -1,
          //是否iPad
          webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部

        };
      }(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase()
    };
    var isMobile = browser.versions.mobile && !browser.versions.iPad;

    try {
      localStorage.setItem("plantIp", returnCitySN["cip"]);
    } catch (error) {
      localStorage.setItem("plantIp", "");
    }

    return {
      isMobile: isMobile
    };
  }
});