import AnswerApi from "../../api";
import { defineComponent, getCurrentInstance, onBeforeMount, provide, ref } from "vue";
import QuestionsPc from "../QuestionsPc.vue";
import QuestionsMob from "../QuestionsMob.vue";
import PfeButton from "../../../../components/PfeButton.vue";
import QLast from "../../questions/QLast.vue";
import Password from "../../questions/Password/Index.vue";

export default defineComponent({
  components: { QuestionsPc, QuestionsMob, PfeButton, QLast, Password },
  props: {
    // 是否移动端
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const loading = ref(true);
    const visible = ref(false);
    const questionsPc = ref(null);
    const questionsMob = ref(null);
    const questionsData = ref({});
    provide("questionsData", questionsData);
    const { proxy } = getCurrentInstance();

    // 获取问卷
    async function getQuetions(addNew = 0) {
      loading.value = true;
      try {
        // 判断系统是否win11
        let system = "";
        const ua = await navigator.userAgentData?.getHighEntropyValues(["platformVersion"]);
        if (navigator.userAgentData?.platform === "Windows") {
          const majorPlatformVersion = parseInt(ua.platformVersion.split(".")[0]);
          if (majorPlatformVersion >= 13) {
            system = 'Windows 11'
          }
        }
        const { data } = await AnswerApi.getQuetions({
          id: proxy.$route.query.sn,
          data: {
            system,
            add_new: addNew,
            source: proxy.$route.query.source || "",
            is_template: proxy.$route.query.is_template || 0,
            user: proxy.$route.query.user,
            channelUCode: proxy.$route.query.channelUCode,
          },
        });
        // 设置浏览器title
        if (data.survey?.seo_title) document.title = data.survey.seo_title;
        // 选项随机并清空关联list选项
        data.questions.forEach((question) => {
          question.list.forEach((list) => {
            if (list.relation_question_index) {
              list.options = [];
            }
            if (question.config.select_random && list.options.length) {
              list.options = list.options.sort(() => Math.random() - 0.5);
            }
          });
        });
        // 答案
        data.answer_infos.forEach((answerInfo) => {
          const question = data.questions.find(
            (question) => question.question_index === answerInfo.question_index
          );
          question.answer = JSON.parse(answerInfo.answer);
        });
        questionsData.value = data;
        // 判断问卷状态
        const { code, msg } = data.action;
        if (code === 302) {
          location.href = msg;
        } else if (code === 20007 || code === 20008 || code === 20009) {
          visible.value = true;
        }
        // 计算页数
        if (data.answer_infos.length) {
          const { question_index } = data.answer_infos[data.answer_infos.length - 1];
          const index = data.answer.pages.findIndex(
            (pages) => pages.findIndex((page) => page === question_index) !== -1
          );
          if (questionsPc.value) questionsPc.value.page = index + 1;
          if (questionsMob.value) questionsMob.value.page = index + 1;
        }
        // MXD配置
        data.answer.mxd_version?.forEach((version) => {
          const question = data.questions.find(
            (question) => question.question_index === version.question_index
          );
          question.version = version;
        });
        // CBC配置
        data.answer.cbc_shelves_version?.forEach((version) => {
          const question = data.questions.find(
            (question) => question.question_index === version.question_index
          );
          question.version = version;
        });
        // 选项隐藏
        hideOptions(data.hide_options);
        // 读取关联选项缓存
        if (code === 20005) {
          const questionsCache = JSON.parse(localStorage.getItem("questionsCache"));
          if (questionsCache?.[proxy.$route.query.sn]) {
            questionsData.value.questions = questionsCache[proxy.$route.query.sn];
          }
        } else if (code !== 20010) {
          const questionsCache = JSON.parse(localStorage.getItem("questionsCache"));
          if (questionsCache?.[proxy.$route.query.sn]) {
            delete questionsCache[proxy.$route.query.sn];
            localStorage.setItem("questionsCache", JSON.stringify(questionsCache));
          }
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    }

    // 选项隐藏
    function hideOptions(hide) {
      const questionIndex = hide?.question_index;
      if (questionIndex) {
        const qustion = questionsData.value.questions.find(
          (qustion) => qustion.question_index === questionIndex
        );
        qustion.hideOptions = hide.option_key || [];
      }
    }

    // 添份新的
    function addNew() {
      getQuetions(1);
      visible.value = false;
      if (questionsPc.value) questionsPc.value.page = 0;
      if (questionsMob.value) questionsMob.value.page = 0;
    }

    // 修改内容
    function edit() {
      visible.value = false;
      questionsData.value.action.code = 20005;
    }

    // 微信一键登录
    function auth() {
      location.href = questionsData.value.action.msg;
    }

    onBeforeMount(() => {
      getQuetions();
    });

    return {
      loading,
      visible,
      questionsPc,
      questionsMob,
      questionsData,
      addNew,
      edit,
      auth,
      getQuetions,
    };
  },
});
