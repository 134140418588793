import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_answer_mob = _resolveComponent("answer-mob");

  var _component_answer_pc = _resolveComponent("answer-pc");

  return _ctx.isMobile ? (_openBlock(), _createBlock(_component_answer_mob, {
    key: 0
  })) : (_openBlock(), _createBlock(_component_answer_pc, {
    key: 1
  }));
}